import { Component } from "react"

export default class InstagramFeed extends Component {
    componentDidMount() {
        this.buildFeed()
    }

    componentDidUpdate() {
        const { username, profileImage } = this.state
        //eslint-disable-next-line
        const regex = /\B\#\w+/gi

        let feedReplacement = document.querySelector("#yujo-insta")

        if (feedReplacement) {
            feedReplacement.innerHTML = `
			<div class="instagram-feed__arrow instagram-feed__arrow--back" id="insta-back-arrow">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.846 451.847" style="margin-left: 6px;fill: white;" width="20">
				<path d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"></path>
				</svg>
			</div>
			<div id="yujo-instagram-feed" class="instagram-feed">
				${this.state.feed
                    .map((image, i) => {
                        if (i >= 6) return null
                        return `<div key=${
                            image.node.id
                        } class="instagram-feed__link">
							<div class="instagram-feed__item">
								<div class="instagram-feed__header">
									<a class="instagram-feed__header-link" rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/p/${
                                        image.node.shortcode
                                    }">
									<img class="instagram-feed__profile-image" src=${profileImage} alt="Yujo Indy Instagram Profile Image"/>
									<span>${username}</span>
									</a>
								</div>
								<img class="instagram-feed__image" src=${
                                    image.node.display_url
                                } alt=${`${image.node.accessibility_caption}`}/>
								<div class="instagram-feed__details">
									<div>
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24 24"><path d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z" fill="currentColor"/></svg>
										${image.node.edge_liked_by.count}
									</div>
									<p class="instagram-feed__text">${
                                        image.node.edge_media_to_caption.edges[
                                            "0"
                                        ].node.text
                                    }</p>
									<div class="instagram-feed__follow">
									<a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/p/${
                                        image.node.shortcode
                                    }">
										${this.formatCommentMsg(image.node.edge_media_to_comment.count)}
									</a>
									<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
									</div>
								</div>
							</div>
						</div>`
                    })
                    .join("")}
			</div>
			<div class="instagram-feed__arrow instagram-feed__arrow--forward" id="insta-foward-arrow">
				<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 451.846 451.847" style="margin-left: 6px;fill: white;" width="20">
				<path d="M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z"></path>
				</svg>
			</div>
			`
        }

        if (feedReplacement) {
            feedReplacement.style.opacity = 1
        }

        if (feedReplacement) {
            document.querySelectorAll(".instagram-feed__text").forEach(x => {
                let found = x.innerHTML.match(regex)
                found &&
                    found.forEach(a => {
                        let b = a.replace("#", "")
                        x.innerHTML = x.innerHTML.replace(a, ``)
                        x.innerHTML += `<a class="instagram-feed__hash-link" href="https://www.instagram.com/explore/tags/${b}">${a}</a>\x20` // adds empty white space
                    })
            })
        }

        if (feedReplacement) {
            document
                .getElementById("insta-foward-arrow")
                .addEventListener("click", () => {
                    document.getElementById("yujo-instagram-feed").scrollLeft += 300
                })

            document
                .getElementById("insta-back-arrow")
                .addEventListener("click", () => {
                    document.getElementById("yujo-instagram-feed").scrollLeft -= 300
                })
        }
    }

    state = {
        bio: "",
        username: "",
        profileImage: "",
        feed: [],
    }

    // alt endpoint
    // https://instagram.com/graphql/query/?query_id=17888483320059182&variables={"id":"8101440794","first":6,"after":null}
    buildFeed = () => {
        fetch("https://www.instagram.com/yujo.indy/?__a=1")
            .then(response => {
                return response.json()
            })
            .then(data => {
                this.setState({
                    bio: data.graphql.user.biography,
                    username: data.graphql.user.username,
                    profileImage: data.graphql.user.profile_pic_url_hd,
                    feed: data.graphql.user.edge_owner_to_timeline_media.edges,
                })
            })
    }

    formatCommentMsg = number => {
        if (number > 1) {
            return `view all ${number} comments`
        } else {
            return `view comment`
        }
    }

    render() {
        return null
    }
}
